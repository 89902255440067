import axios from 'axios'
import jwt_decode from 'jwt-decode'
import store from '@/store'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    async (config) => {
        const useAuth = config.hasOwnProperty('useAuth') ? config.useAuth : true 
        if (useAuth && store.state.auth.token.length) {
            config.headers['Authorization'] = `Bearer ${ store.state.auth.token }`
        }
        return config
    }, 
    error => Promise.reject(error)
)

let requestsToRefresh = []
let isRefreshRequesting = false

axios.interceptors.response.use(null, async err => {
    const { response, config } = err

    if (response.status === 401) {
        if (!store.state.auth.token) return Promise.reject(response)
        if (isRefreshRequesting && config.url.includes('/auth/token/refresh')) return Promise.reject(response)

        if (!isRefreshRequesting) {
            isRefreshRequesting = true

            //console.log('Request for a new token')

            store.dispatch('auth/refreshToken').then(() => {
                //console.log('New token received')
                requestsToRefresh.forEach(cb => cb(store.state.auth.token))
            }).catch(e => {
                // Clear token and redirect
                store.commit('auth/logout', null, { root: true })
                router.push('/login').catch(err => {})
                requestsToRefresh.forEach(cb => cb(null))
            }).finally(() =>  {
                //console.log('Clear queue of failed requests')
                requestsToRefresh = []
                isRefreshRequesting = false
            })
        }

        //console.log(`The request is waiting for a new token... [${config.url}]`)
        return new Promise((resolve, reject) => {
            const cfg = { ...config }
            requestsToRefresh.push(token => {
                //console.log(`The request is run with new token... [${cfg.url}]`)
                if (token) {
                    cfg.headers['Authorization'] = `Bearer ${ token }`
                    resolve(axios(cfg))
                }

                reject(response)
            })
        })
    }

    return Promise.reject(response)
})