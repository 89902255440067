import './axios'
import router from '@/router'
import store from '@/store'
import { registerPrimeVue } from './primevue/primevue'
import { i18n } from './i18n'
import { abilitiesPlugin } from '@casl/vue'

export const registerPlugins = (app) => {
    app.use(store)
        .use(abilitiesPlugin, store.state.auth.ability, {
            useGlobalProperties: true
        })
        .use(router)
        .provide('router', router)
        
    registerPrimeVue(app)
    app.use(i18n)
}