import axios from 'axios'
import { objectToFormData } from '@/composables/objects'

export default class {

    async get(filters) {
        const { data } = await axios({
            url: `/employees`,
            params: filters
        })
        return data
    }

    async getRecent(filters) {
        const { data } = await axios({
            url: `/employees/recent`,
            params: filters
        })
        return data
    }

    async getEmployee(id) {
        if (!id) return {}
        const { data } = await axios({
            url: `/employees/${id}`
        })
        return data
    }

    async getRelatedInfo() {
        const { data } = await axios({
            url: `/employees/related-info`
        })
        return data
    }

    async getManagers(filters) {
        const { data } = await axios({
            url: `/employees/managers`,
            params: filters
        })
        return data
    }

    async setEmployee(employee) {
        const formData = objectToFormData(employee)
        const { data } = await axios({
            url: `/employees`,
            method: 'POST',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        return data
    }

    async deleteEmployee(id) {
        const { data } = await axios({
            url: `/employees/${id}`,
            method: 'DELETE'
        })
        return data
    }

    async getContracts(id) {
        const { data } = await axios({
            url: `/employees/${id}/contracts`
        })
        return data
    }

    async deleteContract(id) {
        const { data } = await axios({
            url: `/employees/contracts/${id}`,
            method: 'DELETE'
        })
        return data
    }

    async getSalaries(id) {
        const { data } = await axios({
            url: `/employees/${id}/salaries`
        })
        return data
    }

    async getLastPayroll(id) {
        const { data } = await axios({
            url: `/employees/${id}/last-payroll`
        })
        return data
    }

    async deleteSalary(id) {
        const { data } = await axios({
            url: `/employees/salaries/${id}`,
            method: 'DELETE'
        })
        return data
    }

    async getCostCenters(id) {
        const { data } = await axios({
            url: `/employees/${id}/cost-centers`
        })
        return data
    }

    async getDocuments(id) {
        const { data } = await axios({
            url: `/employees/${id}/documents`
        })
        return data
    }

    async getContractEvents(id) {
        const { data } = await axios({
            url: `/employees/${id}/contract-events`
        })
        return data
    }

    async getSalaryEvents(id) {
        const { data } = await axios({
            url: `/employees/${id}/salary-events`
        })
        return data
    }

    async getLogRelatedData(id) {
        const { data } = await axios({
            url: `/employees/${id}/log-related-data`
        })
        return data
    }

    async getApps(id) {
        const { data } = await axios({
            url: `/employees/${id}/apps`
        })
        return data
    }

    async getSocialSecurityAndSalaryConcepts(contract, salary) {
        const { data } = await axios({
            url: `/employees/social-security`,
            method: 'POST',
            data: { contract, salary }
        })
        return data
    }

    async duplicateEmployee(id) {
        const { data } = await axios({
            url: `/employees/${id}/duplicate`,
            method: 'POST'
        })
        return data
    }

    async getTotalSalaryConcepts(id_company, salary_concepts) {
        const { data } = await axios({
            url: `/employees/total-salary-concepts`,
            method: 'POST',
            data: { id_company, salary_concepts }
        })
        return data
    }
}