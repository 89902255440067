<template>
    <Dropdown 
        :id="id"
        v-model="dropdownValue"
        :options="dropdownOptions"
        optionLabel="name"
        @change="onChanged"
        :filter="filter"
        :showClear="showClear"
        :dataKey="key"
        :disabled="disabled"
        :filterFields="filterFields"
        :emptyMessage="emptyMessage"
        :loading="loading"
        @beforeShow="onBeforeShow"
        :placeholder="placeholder"
    >
        <template #value="slotProps">
            <slot
                name="value"
                v-bind:value="slotProps.value"
            />
        </template>
        <template #option="slotProps">
            <slot
                name="option"
                v-bind:option="slotProps.option"
            />
        </template>
    </Dropdown>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import { reactive, ref, computed, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
    emits: ['update:modelValue', 'beforeShow'],
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        modelValue: {
            required: true
        },
        mode: {
            type: String,
            required: false,
            default: 'default'
        },
        options: {
            type: Array,
            required: false,
            default: []
        },
        optionValue: {
            type: String,
            required: false,
            default: ''
        },
        dataKey: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        filterFields: {
            type: Array,
            required: false,
            default: null
        },
        showClear: {
            type: Boolean,
            required: false,
            default: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        optionSearch: {
            type: String,
            required: false,
            default: ''
        }
    },
    setup(props, { emit }) {
        const { modelValue, options, optionValue } = toRefs(props)
        const { t } = useI18n()

        const key = computed(() => !props.dataKey.length ? props.optionValue : props.dataKey)

        let dropdown = reactive({
            dropdownOptions: options,
            filter: true,
            showClear: props.showClear,
            optionVal: optionValue
        })

        if (props.mode === 'bool') {
            dropdown = reactive({
                dropdownOptions: [
                    { id: true, name: 'Sí' },
                    { id: false, name: 'No' }
                ],
                filter: false,
                showClear: false,
                optionVal: 'id'
            })
        }

        const dropdownValue = ref(null)

        const onChanged = e => {
            if (!dropdown.optionVal.length) return emit('update:modelValue', e.value)

            const value = e.value ? e.value[dropdown.optionVal] : ''
            emit('update:modelValue', value)
        }

        const setDropdownValue = () => {
            if (modelValue.value === null || modelValue.value === undefined) {
                dropdownValue.value = null
                return null
            }
            
            if (dropdown.optionVal.length) {
                const value = typeof modelValue.value === 'object' ? modelValue.value[dropdown.optionVal] : modelValue.value
                dropdownValue.value = dropdown.dropdownOptions?.find(o => o[dropdown.optionVal] === value)
            } else { 
                const optionVal = dropdown.dropdownOptions?.find(x => {
                    if (props.optionSearch.length) return x[props.optionSearch] === modelValue.value[props.optionSearch]

                    const option = JSON.parse(JSON.stringify(x))
                    Object.keys(option).forEach(k => option[k] === null && delete option[k])
                    const val = JSON.parse(JSON.stringify(modelValue.value))
                    Object.keys(val).forEach(k => val[k] === null && delete val[k])
                    return JSON.stringify(option) === JSON.stringify(val)
                })
                dropdownValue.value = optionVal
                if (!dropdownValue.value) emit('update:modelValue', null)
            }
        }
        
        setDropdownValue()
        watch([modelValue, options], () => setDropdownValue())

        const onBeforeShow = () => emit('beforeShow')

        const emptyMessage = ref(null)
        watch(() => props.loading, (newValue, oldValue) => emptyMessage.value = newValue ? t('labels.processing') : null)

        return {
            ...toRefs(dropdown),
            dropdownValue,
            onChanged,
            key,
            onBeforeShow,
            emptyMessage
        }
    }
}
</script>