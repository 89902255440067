import AuthService from '@/services/auth'
import EmployeeService from '@/services/employee'
import jwt_decode from 'jwt-decode'
import { createMongoAbility } from '@casl/ability'

const authService = new AuthService()
const employeeService = new EmployeeService()

export default {
    namespaced: true,
    state: {
        token: '',
        refreshToken: '',
        error: false,
        errorCode: '',
        company: null,
        allCompanies: false,
        ability: new createMongoAbility(),
        employee: null
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload.token
            state.refreshToken = payload.refreshToken
            state.error = false
            state.errorCode = ''
        },
        logout(state) {
            state.token = ''
            state.refreshToken = ''
            state.error = false,
            state.errorCode = ''
            state.company = null
            state.allCompanies = false
            state.employee = null
        },
        authError(state, payload) {
            state.token = ''
            state.refreshToken = ''
            state.error = payload.error
            state.errorCode = payload.errorCode,
            state.company = null
            state.allCompanies = false
            state.employee = null
        },
        setCompany(state, payload) {
            state.company = payload
        },
        setAllCompanies(state, payload) {
            state.allCompanies = payload
            if (payload) state.company = null
        },
        setEmployee(state, employee) {
            state.employee = employee
        }
    },
    actions: {
        async signIn({ commit, dispatch }, user) {
            try {
                commit('setLoading', true, { root: true })
                const tokenResponse = await authService.getToken(user)
                commit('auth/setToken', { 'token': tokenResponse.token, 'refreshToken': tokenResponse.refresh_token }, { root: true })
                await dispatch('getEmployee')
            } catch(e) {
                console.log(e)
                const error_code = e.data && e.data.error_code && e.data.error_code.length ? e.data.error_code : e.status
                commit('auth/authError', { 'error': true, 'errorCode': error_code }, { root: true })
            } finally {
                commit('setLoading', false, { root: true })
            }
        },
        async signOut({commit, state}) {
            try {
                commit('setLoading', true, { root: true })
                await authService.setTokenExpired(state.token, state.refreshToken)
                commit('auth/logout', null, { root: true })
            } catch(e) {
                console.log(e)
            } finally {
                commit('setLoading', false, { root: true })
            }
        },
        async refreshToken({ commit, state }) {
            try {
                const tokenResponse = await authService.refreshToken(state.token, state.refreshToken)
                commit('auth/setToken', { 'token': tokenResponse.token, 'refreshToken': tokenResponse.refresh_token }, { root: true })
            } catch(e) {
                console.log(e)
                commit('auth/logout', null, { root: true })
            }
        },
        setCompany({ commit }, payload) {
            commit('auth/setCompany', payload, { root: true })
        },
        setAllCompanies({ commit }, payload) {
            commit('auth/setAllCompanies', payload, { root: true })
        },
        async getEmployee({ commit, getters }) {
            const currentUser = getters.getCurrentUser
            const employee = await employeeService.getEmployee(currentUser.id_employee)
            commit('auth/setEmployee', employee, { root: true })
        }
    },
    getters: {
        getCurrentUser: state => state.token ? jwt_decode(state.token) : null,
        getAbility: state => state.ability
    }
}