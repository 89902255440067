import { createI18n } from 'vue-i18n'
import { findValueByKey } from '@/composables/objects'

export const fallbackLocale = 'en'
export const locale = navigator.language.split('-')[0]
//export const locale = 'en'

export const getLocaleMessages = () => {
    const localeFiles = require.context('@/locales', true, /[\w-]+\.json$/i)
    const locales = localeFiles.keys().reduce((locs, loc) => ({ ...locs, [loc.replace(/\.|\/|json/g, '')]: localeFiles(loc) }), {})
    const localeFallback = findValueByKey(locales, fallbackLocale)    
    const messages = {}
    messages[fallbackLocale] = localeFallback

    if (locale !== fallbackLocale && locale in locales) {
        const localeDefault = findValueByKey(locales, locale)
        messages[locale] = localeDefault
    }

    return messages
}

export const i18n = createI18n({
    locale: locale,
    fallbackLocale: fallbackLocale,
    messages: getLocaleMessages()
})