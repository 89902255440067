<template>
    <div class="d-flex flex-column align-items-center justify-content-center spinner">
        <div class="row">
            <div class="d-flex align-items-center">
                <svg viewBox="0 0 120 15" xmlns="https://www.w3.org/2000/svg" :style="{'width': iconWidth, 'height': iconHeight}">
                    <circle cx="15" cy="30" r="15">
                        <animate attributeName="r" from="15" to="15"
                                begin="0s" dur="0.8s"
                                values="15;9;15" calcMode="linear"
                                repeatCount="indefinite" />
                        <animate attributeName="fill-opacity" from="1" to="1"
                                begin="0s" dur="0.8s"
                                values="1;.5;1" calcMode="linear"
                                repeatCount="indefinite" />
                    </circle>
                    <circle cx="60" cy="30" r="9" fill-opacity="0.3">
                        <animate attributeName="r" from="9" to="9"
                                begin="0s" dur="0.8s"
                                values="9;15;9" calcMode="linear"
                                repeatCount="indefinite" />
                        <animate attributeName="fill-opacity" from="0.5" to="0.5"
                                begin="0s" dur="0.8s"
                                values=".5;1;.5" calcMode="linear"
                                repeatCount="indefinite" />
                    </circle>
                    <circle cx="105" cy="30" r="15">
                        <animate attributeName="r" from="15" to="15"
                                begin="0s" dur="0.8s"
                                values="15;9;15" calcMode="linear"
                                repeatCount="indefinite" />
                        <animate attributeName="fill-opacity" from="1" to="1"
                                begin="0s" dur="0.8s"
                                values="1;.5;1" calcMode="linear"
                                repeatCount="indefinite" />
                    </circle>
                </svg>
            </div>
        </div>
        <div class="row">
            <strong class="text-muted" v-if="!hideText" :style="{'font-size': fontSize}">{{ text.length ? text : $t('labels.processing') }}</strong>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'

export default {
    functional: true,
    props: {
        text: {
            type: String,
            required: false,
            default: ''
        },
        hideText: {
            type: Boolean,
            required: false,
            default: false
        },
        iconWidth: {
            type: String,
            required: false,
            default: '40px'
        },
        iconHeight: {
            type: String,
            required: false,
            default: '40px'
        },
        fontSize: {
            type: String,
            required: false,
            default: '1rem'
        }
    }
}
</script>

<style lang="scss" scoped>
    .spinner {
        .spinner-circle {
            opacity: 0.8;
        }

        svg {
            fill: $primary;
        }
    }
</style>