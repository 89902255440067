export const objectToFormData = object => {
    const arrayToFormData = (key, value, formData) => {
        value.forEach((val, index) => {
            if (!val || typeof val !== 'object') {
                formData.append(`${key}[${index}]`, val)
                return
            }
    
            Object.entries(val).forEach(v => {
                if (Array.isArray(v[1])) {
                    const aKey = `${key}[${index}].${v[0]}`
                    const aValue = v[1] === null ? '' : v[1]
                    arrayToFormData(aKey, aValue, formData)
                } else {
                    const val = v[1] === null ? '' : v[1]
                    formData.append(`${key}[${index}].${v[0]}`, val)
                }
            })
        })
    }

    const formData = new FormData()
    Object.entries(object).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (key == 'file_formation') {
                value.forEach((valFile, keyFile) => {
                    const val = valFile === null ? '' : valFile
                    formData.append(`${key}`, valFile)
                })
            } else {
                arrayToFormData(key, value, formData)
            }
        } else {
            const val = value === null ? '' : value
            formData.append(key, val)
        }
    })
    return formData
}

/**
 * Búsqueda de una clave dado su valor dentro de un objeto/array
 * 
 * Ejemplos de uso:
 * const foo = { data: { data2: { data3: 'worked' }, data21: 'rand' }, data4: { id: 15, name: 'find me!' } }
 * console.log(findKeyByValue(foo, (found) => found === 'worked'))
 * console.log(findKeyByValue(foo, (found) => found && found.id && found.id === 15))
 * @param {*} obj Objeto/Array en el que realizar la búsqueda
 * @param {*} equalsExpression Expresión para localizar la clave del objeto/array
 * @returns Devuelve la clave del objeto/array
 */
export const findKeyByValue = (obj, equalsExpression) => {
    for (var [key, v] of Object.entries(obj)) {
        if (Array.isArray(v)) {
            for (let i = 0; i < v.length; i++) {
                let res = findKeyByValue(v[i], equalsExpression)
                if (res !== null && res !== undefined) return res
            }
        } else {
            if (v !== null && v !== undefined) {
                if (typeof (v) === 'object') {
                    if (equalsExpression(v)) return key
                    let res = findKeyByValue(v, equalsExpression)
                    if (res !== null && res !== undefined) return res
                }
                else {
                    let res = equalsExpression(v)
                    if (res) return key
                }
            }
            else continue
        }
    }
}

/**
 * Búsqueda de un valor dada su clave dentro de un objeto
 * @param {*} object Objeto en el que realizar la búsqueda
 * @param {*} key Clave a buscar dentro del objeto
 * @returns Devuelve el valor del objeto
 */
export const findValueByKey = (object, key) => {
    let value
    Object.keys(object).some(k => {
        if (k === key) {
            value = object[k]
            return true
        }
        if (object[k] && typeof object[k] === 'object') {
            value = findValueByKey(object[k], key)
            return value !== undefined
        }
    })
    return value
}

/**
 * Toma un objeto obj y un array de propiedades properties que quieres incluir en el resultado.Búsqueda de un valor dada su clave dentro de un objeto
 * @param {*} obj Objeto original
 * @param {*} properties Array de propiedades que se van a seleccionar pudiéndose renombrar dichas propiedades en el nuevo objeto. 
 * Ejemplo: propertiesToInclude = [{ 'id_employee': 'idEmployee' }, 'name', 'surname', { 'contract.contract_start_date': 'date' }]
 * @returns Devuelve un objeto con las propiedades seleccionadas
 */
export const pickProperties = (obj, properties) => {
    const result = {}
    properties.forEach(prop => {
        let originalProp, newProp
        if (typeof prop === 'string') {
            originalProp = newProp = prop
        } else {
            originalProp = Object.keys(prop)[0]
            newProp = prop[originalProp]
        }

        const keys = originalProp.split('.')
        let currentObj = result
        let currentSource = obj
        keys.forEach((key, index) => {
            if (currentSource && key in currentSource) {
                if (index === keys.length - 1) {
                    if (keys.length > 1) {
                    const newKey = newProp.split('.').slice(-1)[0]
                    currentObj[newKey] = currentSource[key]
                    } else {
                    currentObj[newProp] = currentSource[key]
                    }
                } else {
                    currentObj[key] = currentObj[key] || {}
                    currentObj = currentObj[key]
                    currentSource = currentSource[key]
                }
            }
        })
    })
    return result
}

/**
 * Reemplaza variables dentro de un objeto
 * @param {*} jsonObject Objeto en el que realizar el reemplazo
 * @param {*} variables Objeto con los valores a reemplazar
 * @returns Devuelve el valor del objeto
 */
export const replaceIntoObject = (jsonObject, variables) => {
    const updatedObject = JSON.parse(JSON.stringify(jsonObject))

    const replaceRecursively = obj => {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                replaceRecursively(obj[key])
            } else if (typeof obj[key] === 'string') {
                let match
                const regex = /\${(\w+)}/g 
                while ((match = regex.exec(obj[key])) !== null) {
                    const variableName = match[1]
                    const newValue = variables[variableName]
                    if (newValue !== undefined && (!isNaN(obj[key]) || !isNaN(newValue))) {
                        obj[key] = Number(newValue)
                    } else {
                        obj[key] = obj[key].replace(match[0], newValue)
                    }
                }
            }
        }
    }

    replaceRecursively(updatedObject)
    return updatedObject
}