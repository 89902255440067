<template>
    <loading 
        v-model:active="isLoading" 
        :loader="'dots'">
        <template v-slot:default>
            <Spinner></Spinner>
        </template>
    </loading>
    <Toast/>
    <router-view />
</template>

<script>
    import { computed } from 'vue'
    import store from '@/store'

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Spinner from '@/components/Spinner'

    export default {
        components: { Loading, Spinner },
        setup() {
            const isLoading = computed(() => store.state.isLoading)

            return {
                isLoading
            }
        }
    }
</script>