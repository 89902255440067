import { replaceIntoObject } from '@/composables/objects'

export default store => {
    const ability = store.state.auth.ability

    return store.subscribe(mutation => {
        switch (mutation.type) {
            case 'auth/setToken':
                const currentUser = store.getters['auth/getCurrentUser']
                const rules = require(`./rules/${currentUser.id_role}.json`)
                const replacements = {
                    id_user: parseInt(currentUser.id_employee)
                }
                const rulesInterpolated = rules.map(data => replaceIntoObject(data, replacements))
                if (rulesInterpolated) ability.update(rulesInterpolated)
                break
            case 'auth/logout':
                ability.update([{ inverted: true, action: 'read', subject: 'all' }])
                break
        }
    })
}