const roles = {
    admin: 'Administrador',
    user: 'Usuario',
    ceo: 'CEO',
    budget_manager: 'Responsable de presupuesto',
    manager: 'Manager',
    delegated_manager: 'Manager delegado',
    training_manager: 'Responsable de formación'
}

const importTypes = {
    employees: 'employees',
    payrolls: 'payrolls',
    salaries: 'salaries'
}

const salaryTypes = {
    payroll: 'payroll',
    salaryBonus: 'salary_bonus',
    invoice: 'invoice',
    bonus: 'bonus',
    arrears: 'arrears'
}

const budgetStatus = {
    ongoing: 'ongoing',
    acceptedBlocked: 'accepted_blocked',
    accepted: 'accepted',
    rejected: 'rejected',
    forecast: 'forecast',
    budgetForecast: 'budget_forecast',
    currentForecast: 'current_forecast',
    outdatedForecast: 'outdated_forecast'
}

const documentTypes = {
    dniF: 'dni_front',
    dniB: 'dni_back',
    socialSecurity: 'social_security',
    socialSecurity: 'bank_account'
}

export {
    roles,
    importTypes,
    salaryTypes,
    budgetStatus,
    documentTypes
}