import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import abilityPlugin from '@/store/modules/auth/ability'

const dataState = new createPersistedState({
    //Persistimos únicamente las variables que necesitamos
    reducer: state => ({
        auth: {
            token: state.auth.token,
            refreshToken: state.auth.refreshToken,
            company: state.auth.company,
            allCompanies: state.auth.allCompanies,
            employee: state.auth.employee
        }
    })
})

export default createStore({
    plugins: [ dataState, abilityPlugin ],
    state: {
        isLoading: false,
        loadingTimeout: null,
    },
    mutations: {
        setLoading(state, bool) {
            if (bool) {
                clearTimeout(state.loadingTimeout)
                state.loadingTimeout = setTimeout(function() {
                    state.isLoading = true
                }, 300)
            } else {
                clearTimeout(state.loadingTimeout)
                state.isLoading = false
            }
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading, { root: true })
        }
    },
    modules: {
        auth
    }
})
