import jwt_decode from 'jwt-decode'
import store from '@/store'
import { roles } from '@/composables/types'

const removeDataTableStates = () => Object.keys(sessionStorage)
                                        .filter(k => /^dt-state/.test(k))
                                        .forEach(k => sessionStorage.removeItem(k))

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    {
        path: '/forgot-pass/:token',
        name: 'forgotPass',
        component: () => import(/* webpackChunkName: "ForgotPass" */ '../views/ForgotPass.vue')
    },
    {
        path: '/view-survey-exit/:id_exit_survey',
        name: 'viewExitSurvey',
        component: () => import(/* webpackChunkName: "viewExitSurvey" */ '../views/Surveys/ViewSurvey.vue')
    },
    {
        path: '/',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
        beforeEnter: async (to, from, next) => {
            try {
                jwt_decode(store.state.auth.token);
                return next()
            } catch(e) {
                store.commit('auth/logout', null, { root: true })
                return next('/login')
            }
        },
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
            },
            {
                path: 'employees',
                name: 'employees',
                component: () => import(/* webpackChunkName: "Employees" */ '../views/Employee/Employees.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'employee') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'employee' }
            },
            {
                path: 'employees/:id',
                name: 'employee',
                component: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Employee.vue'),
                meta: { subject: 'employee' },
                props: true
            },
            {
                path: 'salaries',
                name: 'salaries',
                component: () => import(/* webpackChunkName: "Salaries" */ '../views/Salary/Salaries.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'salary') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'salary' }
            },
            {
                path: 'candidates',
                name: 'candidates',
                component: () => import(/* webpackChunkName: "Candidates" */ '../views/Candidate/Candidates.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'candidate') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'candidate' }
            },
            {
                path: 'candidates/:id',
                name: 'candidate',
                component: () => import(/* webpackChunkName: "Candidate" */ '../views/Candidate/Candidate.vue'),
                meta: { authorize: [roles.admin] },
                meta: { subject: 'candidate' }
            },
            {
                path: 'budgets',
                name: 'budgets',
                component: () => import(/* webpackChunkName: "Budgets" */ '../views/Budget/Budgets.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'budget') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'budget' }
            },
            {
                path: 'budgets/:id',
                name: 'budget',
                component: () => import(/* webpackChunkName: "Budget" */ '../views/Budget/Budget.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'budgetEmployee') sessionStorage.removeItem('dt-state-budgets-employees')
                    return next()
                },
                meta: { subject: 'budget' }
            },
            {
                path: 'budgets/:idBudget/employees/:idBudgetEmployee',
                name: 'budgetEmployee',
                component: () => import(/* webpackChunkName: "BudgetEmployee" */ '../views/Budget/Employee.vue'),
                meta: { subject: 'budget' },
                props: true
            },
            {
                path: 'countries',
                name: 'countries',
                component: () => import(/* webpackChunkName: "Countries" */ '../views/Country/Countries.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'country') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'country' }
            },
            {
                path: 'countries/:id',
                name: 'country',
                component: () => import(/* webpackChunkName: "Country" */ '../views/Country/Country.vue'),
                meta: { subject: 'country' }
            },
            {
                path: 'companies',
                name: 'companies',
                component: () => import(/* webpackChunkName: "Companies" */ '../views/Company/Companies.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'company') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'company' }
            },
            {
                path: 'companies/:id',
                name: 'company',
                component: () => import(/* webpackChunkName: "Company" */ '../views/Company/Company.vue'),
                meta: { subject: 'company' }
            },
            {
                path: 'divisions',
                name: 'divisions',
                component: () => import(/* webpackChunkName: "Divisions" */ '../views/Division/Divisions.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'division') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'division' }
            },
            {
                path: 'divisions/:id',
                name: 'division',
                component: () => import(/* webpackChunkName: "Division" */ '../views/Division/Division.vue'),
                meta: { subject: 'division' }
            },
            {
                path: 'departments',
                name: 'departments',
                component: () => import(/* webpackChunkName: "Departments" */ '../views/Department/Departments.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'department') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'department' }
            },
            {
                path: 'departments/:id',
                name: 'department',
                component: () => import(/* webpackChunkName: "Department" */ '../views/Department/Department.vue'),
                meta: { subject: 'department' }
            },
            {
                path: 'workplaces',
                name: 'workplaces',
                component: () => import(/* webpackChunkName: "Workplaces" */ '../views/Workplace/Workplaces.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'workplace') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'workplace' }
            },
            {
                path: 'workplaces/:id',
                name: 'workplace',
                component: () => import(/* webpackChunkName: "Workplace" */ '../views/Workplace/Workplace.vue'),
                meta: { subject: 'workplace' }
            },
            {
                path: 'positions',
                name: 'positions',
                component: () => import(/* webpackChunkName: "Positions" */ '../views/Position/Positions.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'position') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'position' }
            },
            {
                path: 'positions/:id',
                name: 'position',
                component: () => import(/* webpackChunkName: "Position" */ '../views/Position/Position.vue'),
                meta: { subject: 'position' }
            },
            {
                path: 'professional-categories',
                name: 'professionalCategories',
                component: () => import(/* webpackChunkName: "ProfessionalCategories" */ '../views/ProfessionalCategory/ProfessionalCategories.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'professionalCategory') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'professional-category' }
            },
            {
                path: 'professional-categories/:id',
                name: 'professionalCategory',
                component: () => import(/* webpackChunkName: "ProfessionalCategory" */ '../views/ProfessionalCategory/ProfessionalCategory.vue'),
                meta: { subject: 'professional-category' }
            },
            {
                path: 'cost-centers',
                name: 'costCenters',
                component: () => import(/* webpackChunkName: "CostCenters" */ '../views/CostCenter/CostCenters.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'costCenter') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'cost-center' }
            },
            {
                path: 'cost-centers/:id',
                name: 'costCenter',
                component: () => import(/* webpackChunkName: "CostCenter" */ '../views/CostCenter/CostCenter.vue'),
                meta: { subject: 'cost-center' }
            },
            {
                path: 'projects',
                name: 'projects',
                component: () => import(/* webpackChunkName: "Projects" */ '../views/Project/Projects.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'project') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'project' }
            },
            {
                path: 'projects/:id',
                name: 'project',
                component: () => import(/* webpackChunkName: "Project" */ '../views/Project/Project.vue'),
                meta: { subject: 'project' }
            },
            {
                path: 'corporate-levels',
                name: 'corporateLevels',
                component: () => import(/* webpackChunkName: "CorporateLevels" */ '../views/CorporateLevel/CorporateLevels.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'corporateLevel') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'corporate-level' }
            },
            {
                path: 'corporate-levels/:id',
                name: 'corporateLevel',
                component: () => import(/* webpackChunkName: "CorporateLevel" */ '../views/CorporateLevel/CorporateLevel.vue'),
                meta: { subject: 'corporate-level' }
            },
            {
                path: 'corporate-sublevels',
                name: 'corporateSublevels',
                component: () => import(/* webpackChunkName: "CorporateSublevels" */ '../views/CorporateSublevel/CorporateSublevels.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'corporateSublevel') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'corporate-sublevel' }
            },
            {
                path: 'corporate-sublevels/:id',
                name: 'corporateSublevel',
                component: () => import(/* webpackChunkName: "CorporateSublevel" */ '../views/CorporateSublevel/CorporateSublevel.vue'),
                meta: { subject: 'corporate-sublevel' }
            },
            {
                path: 'notional-salary-items',
                name: 'notionalSalaryItems',
                component: () => import(/* webpackChunkName: "NotionalSalaryItems" */ '../views/NotionalSalaryItem/NotionalSalaryItems.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'notionalSalaryItem') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'notional-salary-item' }
            },
            {
                path: 'notional-salary-items/:id',
                name: 'notionalSalaryItem',
                component: () => import(/* webpackChunkName: "NotionalSalaryItem" */ '../views/NotionalSalaryItem/NotionalSalaryItem.vue'),
                meta: { subject: 'notional-salary-item' }
            },
            {
                path: 'salary-items',
                name: 'salaryItems',
                component: () => import(/* webpackChunkName: "SalaryItems" */ '../views/SalaryItem/SalaryItems.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'salaryItem') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'salary-item' }
            },
            {
                path: 'salary-items/:id',
                name: 'salaryItem',
                component: () => import(/* webpackChunkName: "SalaryItem" */ '../views/SalaryItem/SalaryItem.vue'),
                meta: { subject: 'salary-item' }
            },
            {
                path: 'salary-items-categories',
                name: 'salaryItemCategories',
                component: () => import(/* webpackChunkName: "SalaryItemCategories" */ '../views/SalaryItemCategory/SalaryItemCategories.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'salaryItemCategory') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'salary-item-category' }
            },
            {
                path: 'salary-items-categories/:id',
                name: 'salaryItemCategory',
                component: () => import(/* webpackChunkName: "SalaryItemCategory" */ '../views/SalaryItemCategory/SalaryItemCategory.vue'),
                meta: { subject: 'salary-item-category' }
            },
            {
                path: 'currencies-prices',
                name: 'currenciesPrices',
                component: () => import(/* webpackChunkName: "CurrenciesPrices" */ '../views/CurrencyPrice/CurrenciesPrices.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'currencyPrice') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'currency-price' }
            },
            {
                path: 'currencies-prices/:id',
                name: 'currencyPrice',
                component: () => import(/* webpackChunkName: "CurrencyPrice" */ '../views/CurrencyPrice/CurrencyPrice.vue'),
                meta: { subject: 'currency-price' }
            },
            {
                path: 'formation-centers',
                name: 'formationCenters',
                component: () => import(/* webpackChunkName: "formationCenters" */ '../views/FormationCenter/FormationsCenter.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'formationCenter') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'formation-center' }
            },
            {
                path: 'formation-center/:id',
                name: 'formationCenter',
                component: () => import(/* webpackChunkName: "formationCenter" */ '../views/FormationCenter/FormationCenter.vue'),
                meta: { subject: 'formation-center' }
            },
            {
                path: 'document-types',
                name: 'documentTypes',
                component: () => import(/* webpackChunkName: "DocumentTypes" */ '../views/DocumentType/DocumentTypes.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'documentType') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'document-type' }
            },
            {
                path: 'document-types/:id',
                name: 'documentType',
                component: () => import(/* webpackChunkName: "DocumentType" */ '../views/DocumentType/DocumentType.vue'),
                meta: { subject: 'document-type' }
            },
            {
                path: 'import-data',
                name: 'importData',
                component: () => import(/* webpackChunkName: "ImportData" */ '../views/ImportData/ImportData.vue'),
                children: [
                    {
                        path: '',
                        name: 'importDataTypesList',
                        component: () => import(/* webpackChunkName: "ImportDataTypesList" */ '../components/ImportData/TypesList.vue')
                    },
                    {
                        path: 'template',
                        name: 'importDataTemplate',
                        component: () => import(/* webpackChunkName: "ImportDataTemplate" */ '../components/ImportData/Template.vue')
                    }
                ],
                meta: { subject: 'import-data' }
            },
            {
                path: 'reports',
                name: 'reports',
                component: () => import(/* webpackChunkName: "Reports" */ '../views/Report/Reports.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'report') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'report' }
            },
            {
                path: 'surveys',
                name: 'surveys',
                component: () => import(/* webpackChunkName: "Surveys" */ '../views/Surveys/Surveys.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'survey') {
                        removeDataTableStates()
                        sessionStorage.removeItem("paramsSurvey")
                    }
                    return next()
                },
                meta: { subject: 'survey' }
            },
            {
                path: 'survey/:id_employee/:id_exit_survey',
                name: 'survey',
                component: () => import(/* webpackChunkName: "Survey" */ '../views/Surveys/Survey.vue'),
                meta: { subject: 'survey' }
            },
            {
                path: 'formations',
                name: 'formations',
                component: () => import(/* webpackChunkName: "Formations" */ '../views/Formation/Formations.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'formation') {
                        sessionStorage.removeItem("filtersKPI")
                        removeDataTableStates()
                    }
                    return next()
                },
                meta: { subject: 'formation' }
            },
            {
                path: 'formations/:id',
                name: 'formation',
                component: () => import(/* webpackChunkName: "Formation" */ '../views/Formation/Formation.vue'),
                beforeEnter: (to, from, next) => {
                    removeDataTableStates()
                    return next()
                },
                meta: { subject: 'formation' }
            },
            {
                path: 'formations-budgets',
                name: 'formationsBudget',
                component: () => import(/* webpackChunkName: "FormationsBudget" */ '../views/Formation/Budgets.vue'),
                meta: { subject: 'formation' }
            },
            {
                path: 'formations-budgets/:id',
                name: 'formationsBudgets',
                component: () => import(/* webpackChunkName: "FormationsBudget" */ '../views/Formation/Budget.vue'),
                beforeEnter: (to, from, next) => {
                    removeDataTableStates()
                    return next()
                },
                meta: { subject: 'formation' }
            },
            {
                path: 'formations-log',
                name: 'formationsLog',
                component: () => import(/* webpackChunkName: "FormationsLog" */ '../views/Formation/FormationsLog.vue'),
                meta: { authorize: [roles.admin] }
            },
            {
                path: 'formation-log/:id',
                name: 'formationLog',
                component: () => import(/* webpackChunkName: "FormationLog" */ '../views/Formation/FormationLog.vue'),
                meta: { authorize: [roles.admin] }
            },
            {
                path: 'selections',
                name: 'selections',
                component: () => import(/* webpackChunkName: "Selections" */ '../views/Selection/Selections.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'selection') {
                        sessionStorage.removeItem("filtersKPI")
                        removeDataTableStates()
                    }
                    return next()
                },
                meta: { subject: 'selection' }
            },
            {
                path: 'selections/:id',
                name: 'selection',
                component: () => import(/* webpackChunkName: "Selections" */ '../views/Selection/Selection.vue'),
                beforeEnter: (to, from, next) => {
                    removeDataTableStates()
                    return next()
                },
                meta: { subject: 'selection' }
            },
            {
                path: 'selections-budgets',
                name: 'selectionsBudgets',
                component: () => import(/* webpackChunkName: "SelectionsBudgets" */ '../views/Selection/Budgets.vue'),
                meta: { subject: 'selection' }
            },
            {
                path: 'selections-budgets/:id',
                name: 'selectionsBudget',
                component: () => import(/* webpackChunkName: "SelectionsBudget" */ '../views/Selection/Budget.vue'),
                beforeEnter: (to, from, next) => {
                    removeDataTableStates()
                    return next()
                },
                meta: { subject: 'selection' }
            },
            {
                path: 'service-type',
                name: 'serviceTypes',
                component: () => import(/* webpackChunkName: "ServiceTypes" */ '../views/ServiceType/ServiceTypes.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'serviceType') removeDataTableStates()
                    return next()
                },
                meta: { subject: 'service-type' }
            },
            {
                path: 'service-type/:id',
                name: 'serviceType',
                component: () => import(/* webpackChunkName: "ServiceType" */ '../views/ServiceType/ServiceType.vue'),
                meta: { subject: 'service-type' }
            }
        ]
    },
    { path: "/:pathMatch(.*)*", redirect: '/' }
]