import { createApp } from 'vue'
import App from './App.vue'
import { registerPlugins } from './plugins'
import DropDown from './components/DropDown.vue'
import store from '@/store'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss'
import '@fortawesome/fontawesome-pro/scss/brands.scss'
import '@fortawesome/fontawesome-pro/scss/solid.scss'
import '@fortawesome/fontawesome-pro/scss/regular.scss'
import '@fortawesome/fontawesome-pro/scss/light.scss'
import '@/assets/styles/theme.scss'
import '@/assets/styles/flags.css'

(async () => {
    store.state.auth.token && await store.dispatch('auth/refreshToken')

    Chart.register(ChartDataLabels)
    Chart.defaults.plugins.datalabels.display = ctx => ctx.dataset.data[ctx.dataIndex] !== 0
    
    const app = createApp(App)
    registerPlugins(app)
    app.component('DropDown', DropDown)
    app.directive('focus', {
        mounted(el) {
            el.focus()
        }
    })
    app.mount('#app')
})()