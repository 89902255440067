import axios from 'axios'

export default class {

    async getToken(user) {
        const {data} = await axios({
            method: 'POST',
            url: '/auth/token',
            data: user,
            useAuth: false
        })
        return data
    }

    async setTokenExpired(token, refreshToken) {
        await axios({
            method: 'POST',
            url: '/auth/token/revoke',
            data: {
                token: token,
                refresh_token: refreshToken
            },
            useAuth: false
        })
    }

    async refreshToken(token, refreshToken) {
        const {data} = await axios({
            method: 'POST',
            url: '/auth/token/refresh',
            data: {
                token: token,
                refresh_token: refreshToken
            },
            useAuth: false
        })
        return data
    }
    
    async getForgotPassToken(email) {
        const { data } = await axios({
            url: `/auth/forgot-pass/${email}`
        })
        return data
    }

    async forgotPass(token) {
        const {data} = await axios({
            method: 'POST',
            url: '/auth/forgot-pass',
            data: {
                token: token
            },
            useAuth: false
        })
        return data
    }
}