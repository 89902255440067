import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'

const ability = store.state.auth.ability

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior (to, from, savedPosition) {
        let position = { left: 0, top: 0 }
        if (savedPosition) position = savedPosition
        const mainDiv = document.getElementById('main-div')
        if (mainDiv) mainDiv.scroll(position)
        return position
    }
})

//Control de rutas privadas
router.beforeEach((to, from, next) => {
    //Comprobamos si la ruta tiene definida la meta requiresAuth
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const currentUser = store.getters['auth/getCurrentUser']
    const { authorize, subject } = to.meta

    if (!requiresAuth && currentUser && to.path === '/login') {
        //Si el usuario está logueado e intenta volver al login, redirigimos a /
        return next('/')
    } else if (requiresAuth && !currentUser) {
        //Si el usuario no está logueado impedimos que acceda a /
        return next('/login')
    } else if (currentUser && authorize && !authorize.includes(currentUser.role)) {
        //Perfil no autorizado
        return next({ path: '/' })
    } else if (to.path !== '/' && to.path !== '/login' && subject && !ability.can('read', subject)) {
        //Perfil no autorizado
        return next({ path: '/' })
    }
    return next()
})

router.onError(error => {
    console.error(error)
    if (/ChunkLoadError:.*failed./i.test(error.message)) {
        console.error('Reloading Window 1')
        window.location.reload()
    } else if (/Loading.*chunk.*failed./i.test(error.message)) {
        console.error('Reloading Window 2')
        window.location.reload()
    }
})

export default router
